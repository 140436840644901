@import "/fonts/Montserrat/stylesheet.css";
@import "/fonts/Exo2.0/stylesheet.css";
@mobile: 525px;
@tablet: 1024px;
* {
	font-family: 'Montserrat';
	outline: none;
	text-decoration: none;
	z-index: 1;
}

body {
	margin: 0;
	padding: 0;
	color: white;

}

footer {
	background: #1b588f;
	padding-bottom: 80px;
}

.my-container {
	width: 1160px;
}

.footer_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-left: auto;
	margin-right: auto;
	padding: 40px 0;
	font-size: 14px;
	font-weight: 500;

	.footer_item {
		.logo {
			padding-bottom: 30px;
		}

		.copyright {
			line-height: 21px;
			padding-bottom: 15px;
		}

		.phone_container {
			display: flex;
			justify-content: space-between;
		}

		.phone_title {
			font-weight: bold;
		}

		.email {
			line-height: 21px;
		}

		.address {
			line-height: 21px;
			padding-bottom: 30px;
		}

		.description {
			opacity: .5;
			font-style: normal;
			font-weight: 500;
			font-size: 12px !important;
			line-height: 14px;
			p{
				font-size: 12px;
			}
			&:last-child {
				max-width: 360px;
			}
		}
	}
}

.section-5{
	background: #1b588f;
    position: relative;
    padding-top: 53px;
/*	height: 1000px;
	overflow: hidden;*/
	&::before {
		position: absolute;
		content: "";
		background: url("../img/wave1.svg");
		width: 100%;
		min-height: 143px;
		top: -143px;
		z-index: 4;
	}


	.section-5_container {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		gap: 20px;
		padding: 70px 0;
		/*padding-top: 350px;*/
		font-size: 14px;
		font-weight: 500;
		/*padding-bottom: 500px;*/
		.section-5-title{
			margin: 0;
			font-family: "Exo 2.0";
			font-style: normal;
			font-weight: 500;
			font-size: 55px;
			line-height: 54px;
			padding-bottom: 40px;
/*			position: sticky;
			top: -1px;*/
			opacity: 0.8;
			z-index: 1;
			span {
				color: #EB6D40;
			}
		}
		.section-5_part {
			display: flex;
			
			.img {
				min-width: 450px;
			/*	filter: brightness(91%);*/
				position: relative;
				video {
					/*filter: saturate(121%);*/
    				
/*    				position: sticky;
    				top:80px;*/
    				z-index: 10;
				}
			}

			.section-5-title {
				font-family: "Exo 2.0";
				font-style: normal;
				font-weight: 500;
				font-size: 45px;
				line-height: 52px;
				//position: sticky;
				//top: 10px;
				span {
					color: #EB6D40;
				}
			}

			.texts {

				overflow: hidden;
				margin-top: 250px;
				.has_num{
					display: flex;
					.head_number{
						font-family: "Exo 2.0";
						font-style: normal;
						font-weight: 500;
						font-size: 40px;
						line-height: 48px;
						opacity: 0.3;
						padding-right: 32px;
					}
					.text{
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 30px;
						font-style: normal;
						font-weight: 500;
						font-size: 30px;
						line-height: 36px;
						padding-top: 40px;
						border-top: 1px solid rgba(255,255,255,0.3);
						margin-top: 25px;


					}
				}
				.text_item {
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 30px;
					/*padding-bottom: 350px;*/
					max-width: 590px;
					/*opacity: 0;*/
/*					top: 0;
					position: absolute;*/

					&:first-child {
						opacity: 1;
					}
					&:last-child{
						/*padding: 0;*/
					}
					
					

					.item-logos {
						display: flex;
						padding-bottom: 40px;
						.delimeter {
							margin: 0 40px 0 30px;
							opacity: 0.3;
							border: 1px solid #FFFFFF;
						}
					}

					p {
						font-family: "Montserrat";
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 30px;
						padding-bottom: 20px;
					}

					.text {
						font-family: "Exo 2.0";
						font-style: normal;
						font-weight: 500;
						font-size: 30px;
						line-height: 36px;

						span {
							color: #EB6D40;
						}
					}
				}
			}
		}
	}
}

.section-6 {
	background: #045F9D;
	position: relative;
	padding-top: 143px;

	&::before{
		position: absolute;
		content: "";
		background: url("../img/wave4.png");
		width: 100%;
		min-height: 143px;
		top: -143px;
		z-index: 4;
	}

	.section-6_container {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		gap: 20px;
		padding: 70px 0;
		padding-top: 350px;
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 500px;
		.section-6-title{
			margin: 0;
			font-family: "Exo 2.0";
			font-style: normal;
			font-weight: 500;
			font-size: 30px;
			line-height: 36px;
			padding-bottom: 40px;

			span {
				color: #EB6D40;
			}
		}
		.section-6_part {
			display: flex;

			.img {
				min-width: 450px;
				padding-right: 100px;
				img{
				margin-left: -35px;

					width: 100%;
				}
			}

			.section-6-title {
				font-family: "Exo 2.0";
				font-style: normal;
				font-weight: 500;
				font-size: 55px;
				line-height: 66px;

				span {
					color: #EB6D40;
				}
			}

			.texts {
				.has_num{
					display: flex;
					.head_number{
						font-family: "Exo 2.0";
						font-style: normal;
						font-weight: 500;
						font-size: 40px;
						line-height: 48px;
						opacity: 0.3;
						padding-right: 32px;
					}
					.text{
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 30px;
						font-style: normal;
						font-weight: 500;
						font-size: 30px;
						line-height: 36px;
						padding-top: 40px;
						border-top: 1px solid rgba(255,255,255,0.3);
						margin-top: 25px;
					}
				}
				.text_item {
					
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 30px;
					padding-bottom: 350px;
					max-width: 590px;
					&:last-child{
						padding-bottom: 340px;
					}
					&:first-child {
						padding-top: 350px;
					}
				
					.item-logos {
						display: flex;
						padding-bottom: 40px;
						.delimeter {
							margin: 0 40px 0 30px;
							opacity: 0.3;
							border: 1px solid #FFFFFF;
						}
					}

					p {
						font-family: "Montserrat";
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 30px;
						padding-bottom: 20px;
					}

					.text {
						font-family: "Exo 2.0";
						font-style: normal;
						font-weight: 500;
						font-size: 30px;
						line-height: 36px;

						span {
							color: #EB6D40;
						}
					}
				}
			}
		}
	}
}

.section-7 {
	background: #045F9D;
	position: relative;
	overflow: hidden;

	&:before {
		background: url(../img/bg-shape.png) no-repeat;
		content: "";
		position: absolute;
		left: 28%;
		right: 17.5%;
		top: 20%;
		bottom: 16.25%;
		min-height: 678px;
		min-width: 1025px;
	}

	.section-7_container {
		display: flex;
		margin-left: auto;
		margin-right: auto;
		gap: 20px;
		padding: 70px 0;
		padding-top:210px;
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 160px;

		.section-7_img__container {
			position: relative;
			margin-left: -150px;
		}

		.section-7_info__container {
			max-width: 500px;
			padding-top: 60px;

			.info_title {
				.title {
					margin: 0;
					font-family: "Exo 2.0";
					font-style: normal;
					font-weight: 500;
					font-size: 30px;
					line-height: 36px;
					padding-bottom: 40px;

					span {
						color: #EB6D40;
					}
				}
			}

			.info_text {
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				padding-bottom: 30px;

				span {
					font-weight: normal;
				}
			}

			.info_description {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				opacity: 0.5;
				padding-bottom: 30px;
			}

			.info_link {
				a {
					font-size: 16px;
					color: white;
					display: flex;
					justify-content: center;
					background: #EB6D40;
					height: 56px;
					text-align: center;
					align-items: center;
					border-radius: 8px;
					max-width: 360px;
				}
			}
		}
	}
}

.section-8_container {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	padding: 70px 0;
	//padding-top: 150px;
	font-size: 14px;
	font-weight: 500;
	//padding-bottom:105px;
}

section.section-8 {
	//margin-top: 143px;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background: url("../img/wave1.svg");
		width: 100%;
		min-height: 143px;
		top: -143px;
		z-index: 4;
	}

	background: #1b588f;

	.section-8-title {
		font-family: "Exo 2.0";
		font-style: normal;
		font-weight: 500;
		font-size: 55px;
		line-height: 66px;
		margin: 0;
		max-width: 50%;
		padding-bottom: 140px;

		span {
			color: #EB6D40;
		}
	}

	.grid-types {
		display: grid;
		grid-template: "1fr 1fr 1fr";
		grid-template-columns: repeat(3, 360px);
		grid-gap: 40px;

		.gird_item {
			border: 1px solid #fff;
			box-sizing: border-box;
			border-radius: 8px 8px 60px 8px;
			padding: 60px 40px 40px;
			min-height: 430px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: .3s;

			&:hover {
				transition: .3s;
				background: #EB6D40;
				border: 1px solid rgba(255, 255, 255, 0.7);
				opacity: 1;
			}

			.item_title {
				font-family: "Exo 2.0";
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 36px;
				margin: 0;
				padding-bottom: 30px;
			}

			.item_description {
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				opacity: .7;
			}

			.item_mode {
				position: relative;
				display: flex;
				justify-content: flex-end;

				.link {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 60px;
					height: 60px;

					.opacity_circle {
						position: absolute;
						border-radius: 50%;
						width: 60px;
						height: 60px;
						opacity: .1;
						background-color: #fff;

						img {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}
}
@media(max-width: 1440px){
	.section-5 .section-5_container .section-5_part .img video {
		height: 450px;
	}
	.section-7 {
		.section-7_container {
			padding-bottom: 250px;
		}
	}
}
@media (max-width: @tablet) {
	.my-container {
		width: 900px;
	}

}

@media (max-width: @mobile) {
	.my-container {
		width: 315px;
	}

/*	.section-5 {
		display: none;
	}*/
	.section-5 {
		padding-top: 50px;
		&::before{
			top: -52px;
		}
		.section-5_container{
			display: block;
			.section-5-title {
				/*position: relative;*/
				/*display: none;*/
				font-size: 30px !important;
				line-height: 33px !important;
			}
			.section-5_part{
				display: block;
				
				.img {
					min-width: 100%;
					position: sticky;
					top: 0;
					background: #1b588f;
					z-index: 10;
					/*position: sticky;*/
					/*top: 15%;*/
					.scroll-sequence {
						height: 250px;
						width: 160px;
						margin: auto;
					}
					video {
						width: 100%;
						height: 250px;
						/*top: 10px;*/
						position: relative;
					}
				}
				.texts{
					/*top: 54% !important;*/
					margin-top: 120px;
					padding-left: 0px;
					padding-right: 0px;
					.text_item {
						p{
							font-size: 13px;
							line-height: 20px;
						}
						.text {
							padding-top: 20px;
							font-size: 13px;
	    					line-height: 20px;
						}
					}
					.item-logos {
						flex-wrap: wrap;
						padding-bottom: 0px;
						.delimiter {
							display: none;
						}
					}
				}
			}			
		}

	}
	.section-7 {
		.section-7_container {
			flex-wrap: wrap;
			padding: 180px 0px;
		}	
	}
	.section-7 .section-7_container .section-7_img__container img{
		width: 100%;
	}
	section.section-8 {
		.section-8-title {
			font-size: 26px;
			line-height: 32px;
			max-width: 100%;
			text-align: center;
			padding-bottom: 60px;
		}
		.section-8_container {
			padding: 60px 0px;
		}
		.grid-types {
				grid-template: unset;
				grid-template-columns: repeat(1, 1fr);
		}
	}
	footer .footer_container {
		flex-wrap: wrap;
	}
}