@import "/fonts/Montserrat/stylesheet.css";
@import "/fonts/Exo2.0/stylesheet.css";
* {
  font-family: 'Montserrat';
  outline: none;
  text-decoration: none;
  z-index: 1;
}
body {
  margin: 0;
  padding: 0;
  color: white;
}
footer {
  background: #1b588f;
  padding-bottom: 80px;
}
.my-container {
  width: 1160px;
}
.footer_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0;
  font-size: 14px;
  font-weight: 500;
}
.footer_container .footer_item .logo {
  padding-bottom: 30px;
}
.footer_container .footer_item .copyright {
  line-height: 21px;
  padding-bottom: 15px;
}
.footer_container .footer_item .phone_container {
  display: flex;
  justify-content: space-between;
}
.footer_container .footer_item .phone_title {
  font-weight: bold;
}
.footer_container .footer_item .email {
  line-height: 21px;
}
.footer_container .footer_item .address {
  line-height: 21px;
  padding-bottom: 30px;
}
.footer_container .footer_item .description {
  opacity: 0.5;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 14px;
}
.footer_container .footer_item .description p {
  font-size: 12px;
}
.footer_container .footer_item .description:last-child {
  max-width: 360px;
}
.section-5 {
  background: #1b588f;
  position: relative;
  padding-top: 53px;
  /*	height: 1000px;
	overflow: hidden;*/
}
.section-5::before {
  position: absolute;
  content: "";
  background: url("../img/wave1.svg");
  width: 100%;
  min-height: 143px;
  top: -143px;
  z-index: 4;
}
.section-5 .section-5_container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  padding: 70px 0;
  /*padding-top: 350px;*/
  font-size: 14px;
  font-weight: 500;
  /*padding-bottom: 500px;*/
}
.section-5 .section-5_container .section-5-title {
  margin: 0;
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 54px;
  padding-bottom: 40px;
  /*			position: sticky;
			top: -1px;*/
  opacity: 0.8;
  z-index: 1;
}
.section-5 .section-5_container .section-5-title span {
  color: #EB6D40;
}
.section-5 .section-5_container .section-5_part {
  display: flex;
}
.section-5 .section-5_container .section-5_part .img {
  min-width: 450px;
  /*	filter: brightness(91%);*/
  position: relative;
}
.section-5 .section-5_container .section-5_part .img video {
  /*filter: saturate(121%);*/
  /*    				position: sticky;
    				top:80px;*/
  z-index: 10;
}
.section-5 .section-5_container .section-5_part .section-5-title {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 52px;
}
.section-5 .section-5_container .section-5_part .section-5-title span {
  color: #EB6D40;
}
.section-5 .section-5_container .section-5_part .texts {
  overflow: hidden;
  margin-top: 250px;
}
.section-5 .section-5_container .section-5_part .texts .has_num {
  display: flex;
}
.section-5 .section-5_container .section-5_part .texts .has_num .head_number {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  opacity: 0.3;
  padding-right: 32px;
}
.section-5 .section-5_container .section-5_part .texts .has_num .text {
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 25px;
}
.section-5 .section-5_container .section-5_part .texts .text_item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /*padding-bottom: 350px;*/
  max-width: 590px;
  /*opacity: 0;*/
  /*					top: 0;
					position: absolute;*/
}
.section-5 .section-5_container .section-5_part .texts .text_item:first-child {
  opacity: 1;
}
.section-5 .section-5_container .section-5_part .texts .text_item:last-child {
  /*padding: 0;*/
}
.section-5 .section-5_container .section-5_part .texts .text_item .item-logos {
  display: flex;
  padding-bottom: 40px;
}
.section-5 .section-5_container .section-5_part .texts .text_item .item-logos .delimeter {
  margin: 0 40px 0 30px;
  opacity: 0.3;
  border: 1px solid #FFFFFF;
}
.section-5 .section-5_container .section-5_part .texts .text_item p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
}
.section-5 .section-5_container .section-5_part .texts .text_item .text {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}
.section-5 .section-5_container .section-5_part .texts .text_item .text span {
  color: #EB6D40;
}
.section-6 {
  background: #045F9D;
  position: relative;
  padding-top: 143px;
}
.section-6::before {
  position: absolute;
  content: "";
  background: url("../img/wave4.png");
  width: 100%;
  min-height: 143px;
  top: -143px;
  z-index: 4;
}
.section-6 .section-6_container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  padding: 70px 0;
  padding-top: 350px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 500px;
}
.section-6 .section-6_container .section-6-title {
  margin: 0;
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 40px;
}
.section-6 .section-6_container .section-6-title span {
  color: #EB6D40;
}
.section-6 .section-6_container .section-6_part {
  display: flex;
}
.section-6 .section-6_container .section-6_part .img {
  min-width: 450px;
  padding-right: 100px;
}
.section-6 .section-6_container .section-6_part .img img {
  margin-left: -35px;
  width: 100%;
}
.section-6 .section-6_container .section-6_part .section-6-title {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 66px;
}
.section-6 .section-6_container .section-6_part .section-6-title span {
  color: #EB6D40;
}
.section-6 .section-6_container .section-6_part .texts .has_num {
  display: flex;
}
.section-6 .section-6_container .section-6_part .texts .has_num .head_number {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  opacity: 0.3;
  padding-right: 32px;
}
.section-6 .section-6_container .section-6_part .texts .has_num .text {
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 25px;
}
.section-6 .section-6_container .section-6_part .texts .text_item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 350px;
  max-width: 590px;
}
.section-6 .section-6_container .section-6_part .texts .text_item:last-child {
  padding-bottom: 340px;
}
.section-6 .section-6_container .section-6_part .texts .text_item:first-child {
  padding-top: 350px;
}
.section-6 .section-6_container .section-6_part .texts .text_item .item-logos {
  display: flex;
  padding-bottom: 40px;
}
.section-6 .section-6_container .section-6_part .texts .text_item .item-logos .delimeter {
  margin: 0 40px 0 30px;
  opacity: 0.3;
  border: 1px solid #FFFFFF;
}
.section-6 .section-6_container .section-6_part .texts .text_item p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
}
.section-6 .section-6_container .section-6_part .texts .text_item .text {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}
.section-6 .section-6_container .section-6_part .texts .text_item .text span {
  color: #EB6D40;
}
.section-7 {
  background: #045F9D;
  position: relative;
  overflow: hidden;
}
.section-7:before {
  background: url(../img/bg-shape.png) no-repeat;
  content: "";
  position: absolute;
  left: 28%;
  right: 17.5%;
  top: 20%;
  bottom: 16.25%;
  min-height: 678px;
  min-width: 1025px;
}
.section-7 .section-7_container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  padding: 70px 0;
  padding-top: 210px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 160px;
}
.section-7 .section-7_container .section-7_img__container {
  position: relative;
  margin-left: -150px;
}
.section-7 .section-7_container .section-7_info__container {
  max-width: 500px;
  padding-top: 60px;
}
.section-7 .section-7_container .section-7_info__container .info_title .title {
  margin: 0;
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 40px;
}
.section-7 .section-7_container .section-7_info__container .info_title .title span {
  color: #EB6D40;
}
.section-7 .section-7_container .section-7_info__container .info_text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 30px;
}
.section-7 .section-7_container .section-7_info__container .info_text span {
  font-weight: normal;
}
.section-7 .section-7_container .section-7_info__container .info_description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  padding-bottom: 30px;
}
.section-7 .section-7_container .section-7_info__container .info_link a {
  font-size: 16px;
  color: white;
  display: flex;
  justify-content: center;
  background: #EB6D40;
  height: 56px;
  text-align: center;
  align-items: center;
  border-radius: 8px;
  max-width: 360px;
}
.section-8_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 70px 0;
  font-size: 14px;
  font-weight: 500;
}
section.section-8 {
  position: relative;
  background: #1b588f;
}
section.section-8::before {
  position: absolute;
  content: "";
  background: url("../img/wave1.svg");
  width: 100%;
  min-height: 143px;
  top: -143px;
  z-index: 4;
}
section.section-8 .section-8-title {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 66px;
  margin: 0;
  max-width: 50%;
  padding-bottom: 140px;
}
section.section-8 .section-8-title span {
  color: #EB6D40;
}
section.section-8 .grid-types {
  display: grid;
  grid-template: "1fr 1fr 1fr";
  grid-template-columns: repeat(3, 360px);
  grid-gap: 40px;
}
section.section-8 .grid-types .gird_item {
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 8px 8px 60px 8px;
  padding: 60px 40px 40px;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
}
section.section-8 .grid-types .gird_item:hover {
  transition: 0.3s;
  background: #EB6D40;
  border: 1px solid rgba(255, 255, 255, 0.7);
  opacity: 1;
}
section.section-8 .grid-types .gird_item .item_title {
  font-family: "Exo 2.0";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  padding-bottom: 30px;
}
section.section-8 .grid-types .gird_item .item_description {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.7;
}
section.section-8 .grid-types .gird_item .item_mode {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
section.section-8 .grid-types .gird_item .item_mode .link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}
section.section-8 .grid-types .gird_item .item_mode .link .opacity_circle {
  position: absolute;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  opacity: 0.1;
  background-color: #fff;
}
section.section-8 .grid-types .gird_item .item_mode .link .opacity_circle img {
  width: 20px;
  height: 20px;
}
@media (max-width: 1440px) {
  .section-5 .section-5_container .section-5_part .img video {
    height: 450px;
  }
  .section-7 .section-7_container {
    padding-bottom: 250px;
  }
}
@media (max-width: 1024px) {
  .my-container {
    width: 900px;
  }
}
@media (max-width: 525px) {
  .my-container {
    width: 315px;
  }
  /*	.section-5 {
		display: none;
	}*/
  .section-5 {
    padding-top: 50px;
  }
  .section-5::before {
    top: -52px;
  }
  .section-5 .section-5_container {
    display: block;
  }
  .section-5 .section-5_container .section-5-title {
    /*position: relative;*/
    /*display: none;*/
    font-size: 30px !important;
    line-height: 33px !important;
  }
  .section-5 .section-5_container .section-5_part {
    display: block;
  }
  .section-5 .section-5_container .section-5_part .img {
    min-width: 100%;
    position: sticky;
    top: 0;
    background: #1b588f;
    z-index: 10;
    /*position: sticky;*/
    /*top: 15%;*/
  }
  .section-5 .section-5_container .section-5_part .img .scroll-sequence {
    height: 250px;
    width: 160px;
    margin: auto;
  }
  .section-5 .section-5_container .section-5_part .img video {
    width: 100%;
    height: 250px;
    /*top: 10px;*/
    position: relative;
  }
  .section-5 .section-5_container .section-5_part .texts {
    /*top: 54% !important;*/
    margin-top: 120px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .section-5 .section-5_container .section-5_part .texts .text_item p {
    font-size: 13px;
    line-height: 20px;
  }
  .section-5 .section-5_container .section-5_part .texts .text_item .text {
    padding-top: 20px;
    font-size: 13px;
    line-height: 20px;
  }
  .section-5 .section-5_container .section-5_part .texts .item-logos {
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
  .section-5 .section-5_container .section-5_part .texts .item-logos .delimiter {
    display: none;
  }
  .section-7 .section-7_container {
    flex-wrap: wrap;
    padding: 180px 0px;
  }
  .section-7 .section-7_container .section-7_img__container img {
    width: 100%;
  }
  section.section-8 .section-8-title {
    font-size: 26px;
    line-height: 32px;
    max-width: 100%;
    text-align: center;
    padding-bottom: 60px;
  }
  section.section-8 .section-8_container {
    padding: 60px 0px;
  }
  section.section-8 .grid-types {
    grid-template: unset;
    grid-template-columns: repeat(1, 1fr);
  }
  footer .footer_container {
    flex-wrap: wrap;
  }
}
